import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { UserRoles } from '../../core/constants/UserRoles';

@Directive({
  selector: '[appShowForUser]',
})
export class ShowForUserDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthenticationService
  ) {}

  @Input() set appShowForUser(user: UserRoles) {
    if (this.authService.authUser!.role.toLowerCase() == user) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
