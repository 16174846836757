import { Injectable } from '@angular/core';
import { AuthUser } from '../../core/models/admin/users/authUser';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastService } from '../../core/services/toast.service';
import { LoginRequest } from '../../core/models/admin/authentication/loginRequest';
import { LoginResponse } from '../../core/models/admin/authentication/loginResponse';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  authUser: AuthUser | null = this.getAuthUser();

  constructor(
    private http: HttpClient,
    private router: Router,
    private toaster: ToastService
  ) {}

  loginUser(request: LoginRequest) {
    this.http.post<LoginResponse>('/auth/login', request).subscribe({
      next: (loginDetails) => {
        this.setAuthUser(loginDetails);
        this.toaster.successToast('Login success.');
        this.router.navigate(['/tenants']);
      },
    });
  }

  updateProfilePicture(profileUrl: string) {
    var authUser = this.getAuthUser();
    authUser!.profilePicUrl = profileUrl;
    this.setAuthUser(authUser);
  }

  setAuthUser(loginDetails: LoginResponse | null) {
    if (loginDetails == null) {
      localStorage.removeItem('authUser');
      this.authUser = null;
      return;
    }
    this.authUser = loginDetails as AuthUser;
    localStorage.setItem('authUser', JSON.stringify(this.authUser));
  }
  private getAuthUser(): AuthUser | null {
    var authUser = localStorage.getItem('authUser');
    return authUser ? JSON.parse(authUser) : null;
  }
  logoutUser() {
    this.setAuthUser(null);
    this.toaster.successToast('Logout success.');
    this.router.navigate(['/auth/login']);
  }
}
