import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emptyOrValue',
})
export class EmptyOrValuePipe implements PipeTransform {
  transform(value: string|undefined|null, ...args: unknown[]): string {
    if (value == null || value == undefined || value.trim() == '') {
      return '-';
    }
    return value;
  }
}
