<div class="layout-container" [ngClass]="containerClass">
  <app-sidebar [tenantLogoUrl]="authUser.profilePicUrl"></app-sidebar>
  <div class="layout-content-wrapper">
    <app-topbar [profileImage]="authUser.profilePicUrl"></app-topbar>

    <div class="layout-content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-profilemenu
    (signoutEvent)="signOut()"
    (navigateToProfileEvent)="navigateToProfile()"
    (navigateToEvent)="navigateTo($event)"
    [authUser]="authUser"
  ></app-profilemenu>
  <div class="layout-mask"></div>
</div>

<p-confirmDialog></p-confirmDialog>
