import { Pipe, PipeTransform } from '@angular/core';
type Options = 'date' | 'time' | 'date-expand' | 'date-time';

@Pipe({
  name: 'timeDate',
})
export class TimeDatePipe implements PipeTransform {
  transform(value: string | Date, options?: Options): string {
    if (!value) {
      return value;
    }

    const date = new Date(value);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear().toString().slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);

    if (options) {
      if (options == 'date') {
        return `${day}/${month}/${year}`;
      } else if (options == 'date-expand') {
        const monthNames = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        const month = monthNames[date.getMonth()];
        return `${month} ${day} ${year}`;
      } else if (options == 'date-time') {
        return `${day}/${month}/${year} ${hours}:${minutes}`;
      } else {
        return `${hours}:${minutes}`;
      }
    }

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }
}
