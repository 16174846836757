import { Component, OnDestroy, OnInit } from '@angular/core';
import { BroadcasterService } from '../../services/broadcaster.service';
import { AuthUser } from '../../models/admin/users/authUser';
import { AuthenticationService } from '../../../authentication/services/authentication.service';
import { BroadcastKeys } from '../../constants/Broadcast';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  authUser!: AuthUser;

  constructor(
    private broadcastService: BroadcasterService,
    protected authService: AuthenticationService
  ) {
    this.authUser = this.authService.authUser!;
  }

  ngOnInit(): void {
    this.broadcastService
      .listen(BroadcastKeys.HTTP_LOADING)
      .subscribe((loading) => {
        this.isLoading = loading as boolean;
      });
  }
  ngOnDestroy(): void {
    this.broadcastService.unsubscribe();
  }
}
