import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { ConfirmationService } from 'primeng/api';
import { AuthUser } from '../modules/core/models/admin/users/authUser';

@Component({
  selector: 'app-profilemenu',
  templateUrl: './app.profilesidebar.component.html',
})
export class AppProfileSidebarComponent {
  @Input() authUser!: AuthUser;
  @Output() signoutEvent = new EventEmitter<boolean>();
  @Output() navigateToProfileEvent = new EventEmitter<boolean>();
  @Output() navigateToEvent = new EventEmitter<string>();

  constructor(
    private confirmationService: ConfirmationService,
    public layoutService: LayoutService
  ) {}

  get visible(): boolean {
    return this.layoutService.state.profileSidebarVisible;
  }

  set visible(_val: boolean) {
    this.layoutService.state.profileSidebarVisible = _val;
  }
  signOut() {
    this.confirmationService.confirm({
        message: 'Are you sure that you want to proceed?',
        header: 'Sign out',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.layoutService.hideProfileSidebar()
            this.signoutEvent.emit(true);
        },
        reject:()=>{
          this.layoutService.hideProfileSidebar()
        }
    });
  }
  navigateToprofile(){
    this.layoutService.hideProfileSidebar()
    this.navigateToProfileEvent.emit(true);
  }
  navigateTo(url:string){
    this.layoutService.hideProfileSidebar()
    this.navigateToEvent.emit(url);
  }
}
