import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

export type UrlSegment = 'users' | 'routines' | 'posts';

@Injectable({
  providedIn: 'root',
})
export class RouteResolverService {
  public activeUrlSegment!: UrlSegment;
  isParent: boolean = false;
  constructor(private router: Router) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        var segments = event.url.split('/');
        if (segments.length == 2) {
          this.isParent = true;
          this.activeUrlSegment = segments[1];
        } else if (segments.length > 2) {
          this.isParent = false;
        }
      });
  }
}
