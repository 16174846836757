import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { AvatarModule } from 'primeng/avatar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { BadgeModule } from 'primeng/badge';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { PasswordModule } from 'primeng/password';
import { FileUploadModule } from 'primeng/fileupload';
import { MenuModule } from 'primeng/menu';
import { CheckboxModule } from 'primeng/checkbox';
import { TabViewModule } from 'primeng/tabview';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DialogModule } from 'primeng/dialog';
import { SidebarModule } from 'primeng/sidebar';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TagModule } from 'primeng/tag';
import { BlockUIModule } from 'primeng/blockui';
import { DividerModule } from 'primeng/divider';
import { EmptyOrValuePipe } from './pipes/empty-or-value.pipe';
import { ShowForUserDirective } from './directives/show-for-user.directive';
import { ReplaceTokenWithDotPipePipe } from './pipes/replace-token-with-dot.pipe.pipe';
import { LimitStringLengthPipe } from './pipes/limitStringLength';
import { TimeDatePipe } from './pipes/time-date.pipe';
import { PaginatorModule } from 'primeng/paginator';

@NgModule({
  declarations: [EmptyOrValuePipe,ShowForUserDirective,ReplaceTokenWithDotPipePipe,LimitStringLengthPipe,TimeDatePipe],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ToastModule,
    ButtonModule,
    InputTextModule,
    TableModule,
    AvatarModule,
    ConfirmDialogModule,
    BadgeModule,
    DropdownModule,
    MultiSelectModule,
    PasswordModule,
    FileUploadModule,
    MenuModule,
    CheckboxModule,
    TabViewModule,
    DynamicDialogModule,
    DialogModule,
    SidebarModule,
    SelectButtonModule,
    TagModule,
    BlockUIModule,
    DividerModule,
    PaginatorModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ToastModule,
    ButtonModule,
    InputTextModule,
    TableModule,
    AvatarModule,
    ConfirmDialogModule,
    BadgeModule,
    DropdownModule,
    MultiSelectModule,
    PasswordModule,
    FileUploadModule,
    MenuModule,
    CheckboxModule,
    TabViewModule,
    DynamicDialogModule,
    DialogModule,
    SidebarModule,
    SelectButtonModule,
    TagModule,
    BlockUIModule,
    DividerModule,
    EmptyOrValuePipe,
    ShowForUserDirective,
    ReplaceTokenWithDotPipePipe,
    LimitStringLengthPipe,
    TimeDatePipe,
    PaginatorModule
  ],
})
export class SharedModule {}
