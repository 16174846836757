<div
  class="layout-sidebar"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
>
  <div class="sidebar-header">
    <a
      [routerLink]="['/tenant']"
      class="app-logo flex justify-content-center align-items-center"
    >
      <img id="login-logo" src="assets/icons/logo-no-text.jpg" />
      <p id="logo-title">TeamPal</p>
    </a>

    <button
      class="layout-sidebar-anchor p-link z-2 mb-2"
      type="button"
      (click)="anchor()"
    ></button>
  </div>

  <div #menuContainer class="layout-menu-container">
    <app-menu></app-menu>
  </div>
</div>
