import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceTokenWithDot',
})
export class ReplaceTokenWithDotPipePipe implements PipeTransform {
  transform(value: string | undefined): string {
    return '*****';
  }
}
