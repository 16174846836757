import { OnInit } from '@angular/core';
import { Component } from '@angular/core';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];

    ngOnInit() {
        this.model = [
            {
                label: 'Users',
                icon: 'pi pi-user',
                items: [
                    {
                        label: 'Users',
                        icon: 'pi pi-fw pi-user',
                        routerLink: ['/users']
                    },
                ]
            },
            {
                label: 'Tenants',
                icon: 'pi pi-building',
                items: [
                    {
                        label: 'List',
                        icon: 'pi pi-fw pi-building',
                        routerLink: ['/tenants']
                    },
                ]
            },
            {
                label: 'Features',
                icon: 'pi pi-table',
                items: [
                    {
                        label: 'List',
                        icon: 'pi pi-fw pi-table',
                        routerLink: ['/features']
                    },
                ]
            },
            {
                label: 'Products',
                icon: 'pi pi-shop',
                items: [
                    {
                        label: 'List',
                        icon: 'pi pi-shop',
                        routerLink: ['/shop']
                    },
                    {
                        label: 'Create',
                        icon: 'pi pi-plus',
                        routerLink: ['/shop/create-product']
                    },
                    {
                        label: 'Orders',
                        icon: 'pi pi-cart-arrow-down',
                        routerLink: ['/shop/orders']
                    },
                ]
            }
        ];
    }
}
