<nav class="layout-breadcrumb flex">
  <ol>
    <ng-template
      ngFor
      let-item
      let-last="last"
      [ngForOf]="breadcrumbs$ | async"
    >
      <li
        [class]="isRoot(item) ? 'cursor-pointer border-bottom-1' : ''"
        (click)="navigation(item)"
      >
        {{ item.label }}
      </li>
      <li *ngIf="!last" class="layout-breadcrumb-chevron">/</li>
    </ng-template>
  </ol>
</nav>
