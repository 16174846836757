import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private messageService: MessageService) {}

  errorToast(header: string, message: string) {
    this.messageService.add({
      severity: 'error',
      summary: header,
      detail: message,
    });
  }
  successToast(message: string, header: string = 'Success') {
    this.messageService.add({
      severity: 'success',
      summary: header,
      detail: message,
    });
  }
}
